import React, { FC, useEffect } from "react";

export const PasswordRecovery: FC = () => {
    const redirect = () => {
        const queryString = window.location.search;
        window.location.replace(`https://olyns.com/recover-password${queryString}`);
    };
    useEffect(() => {
        redirect();
    }, []);
    return <div/>
};