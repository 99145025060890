import React from 'react'
import './App.css';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { VerifyAccount } from './components/VerifyAccount';
import { PasswordRecovery } from './components/PasswordRecovery';

function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<VerifyAccount />} />
        <Route path='/verify-account' element={<VerifyAccount />} />
        <Route path='/recover-password' element={<PasswordRecovery />} />
      </Routes>
    </Router>
  );
}

export default App;
